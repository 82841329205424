$bottom_tab_width: 48px;

.Home-MobileContainer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.Home-NoaContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  padding-bottom: $bottom_tab_width;
}

.Home-CodeContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  padding-bottom: $bottom_tab_width;
}

.Home-MobileSwitcher {
  position: absolute;
  height: $bottom_tab_width;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #343a40 !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}