.Console-Error {
  color: white;
}

.Console-ScrollableFeed {
  padding: 8px;
}

.Console-MessageFeed {
  height: 100%;
  background-color: darkslategray;
}