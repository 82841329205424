.CodeEditor {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.CodeEditor-Editor {
  flex: 1 1 auto;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.CodeEditor-Editor::-webkit-scrollbar {
  display: none;
}

.CodeEditor-Header {
  padding: 4px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: darkslategray;
}

.CodeEditor-Header-Button {
  margin-right: 4px;
}

.btn:focus, .btn:active:focus, .btn.active:focus {
  box-shadow: none !important;
}

.CodeEditor-Header-Text {
  color: white;
}

.CodeEditor-EditorContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}
