.App {
  background-color: darkslategray;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-MainContainer {
  position: relative;
  flex: 1 1 auto;
}

#noa_fps {
  bottom: 0px;
  left: 0px;
  top: auto !important;
  right: auto !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}